<template>
  <pgp-key-input
    v-if="!loading"
    class="pgp-key-input-with-criteria"
    :redirect-url="redirectUrl"
    :pgp-key="pgpKey"
    @input="onPgpInputChange"
  >
    <template #label>
      <slot name="label" />
    </template>
    <template #actions>
      <div class="pgp-key-input-with-criteria__actions d-flex flex-column">
        <div class="pgp-key-input-with-criteria__actions__criteria">
          <p class="mb-0">This key must:</p>
          <ul class="pgp-key-input-with-criteria__actions__criteria__list list-unstyled">
            <li
              v-for="item in criteriaList"
              :key="item.name"
              class="my-1 py-0 pl-3 d-flex align-items-center"
              :class="`pgp-key-input-with-criteria__actions__criteria__list__${item.name}`"
            >
              <fa
                :icon="item.icon"
                :class="item.class"
                class="pgp-key-input-with-criteria__requirements__icon mr-2"
                fixed-width
              />
              <span class="d-flex flex-wrap pgp-key-input-with-criteria__requirements__text">
                {{ item.text }}
              </span>
            </li>
          </ul>
        </div>
        <slot name="buttons" />
      </div>
    </template>
  </pgp-key-input>
</template>

<script>
import PgpKeyInput from '@/components/PgpKeyInput'

export const CRITERIA = Object.freeze({
  USING_RSA: 'usingRsa',
  KEY_LENGTH: 'keyLength',
  OWNED_EMAIL: 'ownedEmail',
  IS_PUBLIC: 'isPublic'
})

const CRITERIA_TEXT = Object.freeze({
  [CRITERIA.USING_RSA]: 'have been generated with RSA algorithm (not ECC nor other algorithms)',
  [CRITERIA.KEY_LENGTH]: 'have a length of 1024 bits minimum ',
  [CRITERIA.OWNED_EMAIL]: 'be linked to the email address provided to ICIJ',
  [CRITERIA.IS_PUBLIC]: 'be public, not private'
})

export const CRITERIA_STATUS = Object.freeze({
  NONE: 'none',
  VALID: 'valid',
  INVALID: 'invalid'
})

const STATUS_ICON = Object.freeze({
  [CRITERIA_STATUS.NONE]: ['far', 'square'],
  [CRITERIA_STATUS.VALID]: 'square-check',
  [CRITERIA_STATUS.INVALID]: 'square-xmark'
})

const STATUS_CLASS = Object.freeze({
  [CRITERIA_STATUS.NONE]: '--none',
  [CRITERIA_STATUS.VALID]: 'text-success',
  [CRITERIA_STATUS.INVALID]: 'text-danger'
})

export default {
  name: 'PgpKeyInputWithCriteria',
  components: {
    PgpKeyInput
  },
  inject: ['api'],
  props: {
    email: {
      type: String,
      required: true
    },
    pgpKey: {
      type: String,
      default: ''
    },
    autofill: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keyInfo: {},
      criteria_status: {
        [CRITERIA.USING_RSA]: CRITERIA_STATUS.NONE,
        [CRITERIA.KEY_LENGTH]: CRITERIA_STATUS.NONE,
        [CRITERIA.OWNED_EMAIL]: CRITERIA_STATUS.NONE,
        [CRITERIA.IS_PUBLIC]: CRITERIA_STATUS.NONE
      },
      loading: false
    }
  },
  computed: {
    allCriteriaValid() {
      return Object.values(this.criteria_status).every((criteria) => {
        return criteria === CRITERIA_STATUS.VALID
      })
    },
    criteriaList() {
      return Object.values(CRITERIA).map((criteriaName) => ({
        name: criteriaName,
        text: CRITERIA_TEXT[criteriaName],
        class: STATUS_CLASS[this.criteria_status[criteriaName]],
        icon: STATUS_ICON[this.criteria_status[criteriaName]]
      }))
    },
    redirectUrl() {
      return '/onboarding/update-pgp-key'
    }
  },
  watch: {
    email: {
      handler() {
        this.updateCriteriaStatus()
      },
      immediate: true
    }
  },
  async created() {
    if (this.autofill) {
      this.loading = true
      const { pgp_key: pgpKey } = await this.api.onboardingPgpKey()
      this.$emit('pgp-input-changed', {
        isValid: this.enableUpload(pgpKey),
        pgpKey
      })
      this.loading = false
    }
  },
  methods: {
    checkPgpKey(pgpInput) {
      return this.api.onboardingCheckPgpKey(pgpInput)
    },
    status(condition) {
      return condition ? CRITERIA_STATUS.VALID : CRITERIA_STATUS.INVALID
    },

    async onPgpInputChange(pgpInput) {
      this.resetCriteriaStatus()
      const pgpInputTrim = pgpInput.trim()
      if (pgpInputTrim.length >= 128) {
        try {
          const keyInfo = await this.checkPgpKey(pgpInputTrim)
          this.keyInfo = Object.assign({}, this.keyInfo, keyInfo)
          this.updateCriteriaStatus()
        } catch (e) {
          this.setCriteriaStatus(CRITERIA_STATUS.INVALID)
        }
      } else if (pgpInputTrim.length > 0 && pgpInputTrim.length < 128) {
        this.criteria_status[CRITERIA.KEY_LENGTH] = CRITERIA_STATUS.INVALID
      }

      this.$emit('pgp-input-changed', {
        isValid: this.enableUpload(pgpInputTrim),
        pgpKey: pgpInputTrim
      })
    },
    updateCriteriaStatus() {
      this.criteria_status = {
        [CRITERIA.USING_RSA]: this.status(this.keyInfo?.algo === '1'),
        [CRITERIA.KEY_LENGTH]: this.status(this.keyInfo?.key_length >= 1024),
        [CRITERIA.OWNED_EMAIL]: this.status(
          this.email.length && this.keyInfo?.uids?.some((uid) => uid?.uid_string?.includes(this.email))
        ),
        [CRITERIA.IS_PUBLIC]: this.status(this.keyInfo?.type === 'pub')
      }
    },
    setCriteriaStatus(status) {
      this.criteria_status = {
        [CRITERIA.USING_RSA]: status,
        [CRITERIA.KEY_LENGTH]: status,
        [CRITERIA.OWNED_EMAIL]: status,
        [CRITERIA.IS_PUBLIC]: status
      }
    },
    resetCriteriaStatus() {
      this.setCriteriaStatus(CRITERIA_STATUS.NONE)
    },
    enableUpload(pgpInputTrim) {
      return pgpInputTrim.length !== 0 && this.allCriteriaValid
    }
  }
}
</script>
