<template>
  <b-form-group id="create-user-profile" class="p-3 rounded shadow-sm border mb-4">
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="First Name" label-for="firstName" label-class="control-label--required">
          <b-form-input id="firstName" :value="firstName" required @input="updateParentField('firstName', $event)" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Last Name" label-for="lastName" label-class="control-label--required">
          <b-form-input id="lastName" :value="lastName" required @input="updateParentField('lastName', $event)" />
        </b-form-group>
      </b-col>
    </b-row>

    <uid-input :value="uid" @update="onUpdate" @uid-validation="onUidValidation" />

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Organization" label-for="organization" label-class="control-label--required">
          <b-form-input
            id="organization"
            :value="organization"
            required
            @input="updateParentField('organization', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Country" label-class="control-label--required">
          <country-select :selected="country" name="country" @input="updateParentField('country', $event)" />
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="errors.length" class="text-danger">
      <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
    </div>

    <user-creation-navigation :show-previous="false" :validate-step="validateAllFields" v-on="$listeners" />
  </b-form-group>
</template>

<script>
import CountrySelect from '@/components/CountrySelect'
import UidInput from '@/components/UserForm/UidInput'
import UserCreationNavigation from '@/components/UserForm/UserCreationNavigation'

const REQUIRED_FIELDS = ['firstName', 'lastName', 'organization', 'country']

export default {
  name: 'ProfileStep',
  components: {
    CountrySelect,
    UidInput,
    UserCreationNavigation
  },
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    uid: {
      type: String,
      required: true
    },
    organization: {
      type: String,
      required: true
    },
    country: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uidEdited: false,
      errors: [],
      uidErrors: ''
    }
  },
  watch: {
    firstName(newFirst) {
      this.updateUid(newFirst, this.lastName)
    },
    lastName(newLast) {
      this.updateUid(this.firstName, newLast)
    }
  },
  methods: {
    updateParentField(field, value) {
      this.$emit('update-field', { field, value })
    },
    onUidValidation(error) {
      this.uidError = error
    },
    validateAllFields() {
      this.errors = []

      REQUIRED_FIELDS.forEach((field) => {
        if (this[field] === '') {
          this.errors.push(`${field.charAt(0).toUpperCase() + field.slice(1)} is required.`)
        } else {
          this.errors = this.errors.filter((error) => !error.includes(field))
        }
      })

      if (this.uidError) {
        this.errors.push(this.uidError)
      }

      return this.errors.length === 0
    },
    updateUid(firstName, lastName) {
      if (!this.uidEdited && firstName && lastName) {
        const newUid = `${firstName.charAt(0).toLowerCase()}${lastName.toLowerCase()}`
        this.updateParentField('uid', newUid)
      }
    },
    onUpdate($event) {
      this.uidEdited = true
      this.updateParentField('uid', $event)
    }
  }
}
</script>
