<script>
import { format } from 'date-fns'

import FormButton from '@/components/FormButton'

export default {
  name: 'UserSecurityClientCertificates',
  components: {
    FormButton
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    here() {
      return `/users/${this.user.id}#/security/client-certificate`
    },
    blockUserData() {
      return { to_remove: ['user'] }
    },
    activateUserData() {
      return { to_add: ['user'] }
    },
    certificateExpired() {
      return this.user.certificate_expired
    },
    expirationLabel() {
      return this.certificateExpired ? 'The certificate expired on' : 'The certificate expires on'
    }
  },
  methods: {
    generateUrl(path) {
      return this.root + path
    },
    hasPermission(name) {
      return this.userPermissions[name]
    },
    formatDate(date, options = { short: false }) {
      return format(new Date(date), `dd MMM yyyy ${options.short ? '' : ', h:mm aaaa'}`)
    }
  }
}
</script>

<template>
  <div class="user-security-client-certificates">
    <h3>Client Certificates</h3>
    <div class="row">
      <div class="mb-5 col-12 col-lg-7">
        <p class="text-muted">
          Client certificates are issued by ICIJ and add an extra layer of security to some of our platforms.
        </p>
        <b-card no-body class="mb-3">
          <b-list-group flush>
            <b-list-group-item
              v-if="hasPermission('create_certificate')"
              class="user-security-client-certificates__creation d-flex align-items-center"
            >
              <template v-if="user.user_certificate_exists">
                <fa icon="check" fixed-width class="text-success mr-1" />
                Certificate created
                <div class="ml-auto">
                  <form-button
                    v-if="hasPermission('revoke_certificate')"
                    class="mr-1"
                    :url="generateUrl('/certificates/revoke')"
                    :redirect="here"
                    size="sm"
                    variant="outline-primary"
                  >
                    <fa icon="trash" class="mr-1" />
                    Revoke
                  </form-button>

                  <form-button
                    v-if="hasPermission('send_certificate_by_email')"
                    v-b-tooltip
                    :url="generateUrl('/certificates/send_by_email')"
                    :redirect="here"
                    title="This will revoke the certificate and send a new one"
                    size="sm"
                    variant="outline-primary"
                  >
                    <fa icon="paper-plane" class="mr-1" />
                    Send
                  </form-button>
                </div>
              </template>
              <template v-else>
                <fa icon="times" fixed-width class="text-danger mr-1" />
                Certificate not created yet
                <form-button
                  :url="generateUrl('/certificates/create')"
                  :redirect="here"
                  class="ml-auto"
                  variant="primary"
                  size="sm"
                >
                  <fa icon="plus" class="mr-1" />
                  Create
                </form-button>
              </template>
            </b-list-group-item>
          </b-list-group>
          <b-card-footer
            v-if="user.certificate_creation_date"
            class="user-security-client-certificates__creation__expiration_date small py-1 px-2 text-muted"
            :class="{
              'user-security-client-certificates--expired': certificateExpired
            }"
          >
            {{ expirationLabel }}
            <abbr class="font-weight-bold" :title="formatDate(user.certificate_expiration_date)">{{
              formatDate(user.certificate_expiration_date, { short: true })
            }}</abbr>
          </b-card-footer>
        </b-card>
        <p>
          Any troubles with your certificate?
          <a href="/certificate">Follow the tutorial</a>.
        </p>
      </div>
      <div class="col text-center">
        <img src="@/assets/images/certificate.svg" class="mw-100" width="250" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'node_modules/bootstrap/scss/_functions.scss';
@import '../utils/variables';

.user-security-client-certificates {
  .card-footer {
    color: $text-muted;
  }

  &--expired {
    background: theme-color-level('danger', $alert-bg-level);
    color: theme-color-level('danger', $alert-color-level);
  }
}
</style>
