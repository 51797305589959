<template>
  <div class="user-creation-page d-flex flex-column">
    <slot name="flash" />

    <user-creation-progress-bar
      :user-creation-steps-array="userCreationStepsArray"
      :current-step="userCreationStep"
      :steps="steps"
      class="mb-4"
    />

    <div class="flex-grow-1">
      <component
        :is="currentStepComponent"
        :user="userPresent && user"
        v-bind="form"
        @update-field="updateField"
        @previous-step="goToPreviousStep"
        @next-step="goToNextStep"
      />
    </div>
  </div>
</template>

<script>
import { snakeCase, cloneDeep } from 'lodash'

import UserCreationProgressBar from '@/components/UserForm/UserCreationProgressBar'
import ProfileStep from '@/components/UserForm/ProfileStep'
import EmailStep from '@/components/UserForm/EmailStep'
import AccessStep from '@/components/UserForm/AccessStep'
import RecapStep from '@/components/UserForm/RecapStep'

export const STEPS = Object.freeze({
  PROFILE: 1,
  EMAIL: 2,
  ACCESS: 3,
  RECAP: 4
})

const STEP_COMPONENTS = Object.freeze({
  [STEPS.PROFILE]: ProfileStep,
  [STEPS.EMAIL]: EmailStep,
  [STEPS.ACCESS]: AccessStep,
  [STEPS.RECAP]: RecapStep
})

export default {
  name: 'UserCreationPage',
  components: {
    UserCreationProgressBar
  },
  inject: ['api'],
  data() {
    return {
      userCreationStep: STEPS.PROFILE,
      form: {
        firstName: '',
        lastName: '',
        organization: '',
        country: '',
        uid: '',
        email: '',
        pgpKey: '',
        expiresAt: '',
        userGroups: [],
        blockedApplications: []
      },
      user: {}
    }
  },
  computed: {
    currentStepComponent() {
      return STEP_COMPONENTS[this.userCreationStep]
    },
    userCreationStepsArray() {
      return Object.keys(this.steps)
    },
    steps() {
      return { ...STEPS }
    },
    serializedForm() {
      const payload = cloneDeep(this.form)

      for (const key in payload) {
        const snakeKey = snakeCase(key)

        if (snakeKey !== key) {
          payload[snakeKey] = payload[key]
          delete payload[key]
        }
      }

      payload.user_groups_attributes = payload.user_groups
      payload.blocked_applications_attributes = payload.blocked_applications
      delete payload.user_groups
      delete payload.blocked_applications

      return payload
    },
    userPresent() {
      return Object.keys(this.user).length > 0
    }
  },
  methods: {
    updateField({ field, value }) {
      this.$set(this.form, field, value)
    },
    goToPreviousStep() {
      if (this.userCreationStep > this.steps.PROFILE) {
        this.userCreationStep -= 1
      }
    },
    goToNextStep() {
      if (this.userCreationStep < this.steps.ACCESS) {
        this.userCreationStep += 1
      } else {
        this.submitForm()
      }
    },
    async submitForm() {
      try {
        this.user = await this.api.createUser(this.serializedForm)
        this.userCreationStep += 1
        this.$bvToast.toast('User created', {
          variant: 'success'
        })
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: 'The user could not be created',
          variant: 'danger'
        })
      }
    }
  }
}
</script>
