<template>
  <section class="onboarding-upload-pgp-key d-flex flex-column col-9 col-lg-12 mx-auto">
    <div class="col-lg-10 mx-auto onboarding-upload-pgp-key__content">
      <pgp-key-input-with-criteria :user-id="userId" :email="email" @pgp-input-changed="onDisableChange">
        <template #label>
          <p class="onboarding-upload-pgp-key__content__label font-weight-bold">
            Please upload your <u class="text-primary">public</u> key here:
          </p>
        </template>
      </pgp-key-input-with-criteria>
    </div>
    <footer class="onboarding-upload-pgp-key__footer d-flex justify-content-between mt-4">
      <b-btn
        variant="link"
        size="lg"
        class="onboarding-upload-pgp-key__footer__actions__back text-dark font-weight-bold pl-0"
        @click="handleBackClicked"
        ><fa icon="circle-left" fixed-width class="mr-1" />Back</b-btn
      >
      <onboarding-pgp-upload-button :pgp-key="pgpKey" :disabled="uploadDisabled" />
    </footer>
  </section>
</template>

<script>
import PgpKeyInputWithCriteria from '@/components/PgpKeyInputWithCriteria'
import OnboardingPgpUploadButton from '@/components/onboarding/OnboardingPgpUploadButton'

export default {
  name: 'OnboardingUploadPgpKey',
  components: { PgpKeyInputWithCriteria, OnboardingPgpUploadButton },
  props: {
    userId: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uploadDisabled: true,
      pgpKey: ''
    }
  },
  methods: {
    onDisableChange({ isValid, pgpKey }) {
      this.uploadDisabled = !isValid
      this.pgpKey = pgpKey
    },
    handleBackClicked() {
      this.$emit('back-clicked')
    }
  }
}
</script>
