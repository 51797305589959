<script>
import { cloneDeep } from 'lodash'

import FormButton from '@/components/FormButton'
import PgpKey from '@/components/PgpKey'
import XemxForm from '@/components/XemxForm'

export default {
  name: 'UserEmails',
  components: {
    XemxForm,
    FormButton,
    PgpKey
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: cloneDeep(this.user)
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    here() {
      return `${this.root}#/email`
    },
    sendTestEmailUrl() {
      return `${this.root}/test_email`
    },
    sendConfirmationEmailUrl() {
      return `${this.root}/send_confirmation_email`
    },
    skipConfirmationEmailUrl() {
      return `${this.root}/skip_confirmation_email`
    },
    updateEmailUrl() {
      return `${this.root}/update_email`
    },
    syncWithCiphermail() {
      return `${this.root}/sync_with_ciphermail`
    }
  },
  methods: {
    hasPermission(name) {
      return this.userPermissions[name]
    }
  }
}
</script>

<template>
  <div class="user-emails">
    <h3>Emails</h3>
    <div class="row">
      <dl class="mb-5 col-12 col-lg-7">
        <dt>Verification</dt>
        <dd>
          <p v-if="!user.email_is_unconfirmed">
            <fa icon="check" fixed-width class="text-success mr-1" />
            Email associated to this account has been confirmed.
          </p>
          <div v-else class="mb-3">
            <p class="mb-2">
              <fa icon="times" fixed-width class="text-danger mr-1" />
              Email associated to this account must be confirmed.
            </p>
            <form-button :url="sendConfirmationEmailUrl" :redirect="here" variant="primary" size="sm" class="mr-1">
              <fa icon="envelope" class="mr-1" />
              Send confirmation to <strong>{{ user.unconfirmed_email }}</strong>
            </form-button>
            <form-button
              v-if="hasPermission('skip_confirmation_email')"
              :url="skipConfirmationEmailUrl"
              :redirect="here"
              variant="primary"
              size="sm"
            >
              <fa icon="check" class="mr-1" />
              Skip confirmation
            </form-button>
          </div>
        </dd>

        <dt class="mb-2">Primary email address</dt>
        <dd>
          <xemx-form inline class="mb-2" method="POST" :action="updateEmailUrl">
            <b-form-group class="mr-2" label="Change your email" label-class="sr-only">
              <b-form-input v-model="form.email" type="email" required name="email" />
            </b-form-group>

            <b-button type="submit" variant="primary" :disabled="form.email === user.email"> Update </b-button>
          </xemx-form>
          <p class="text-muted small">This email is used for all communications with your account.</p>
        </dd>

        <dt class="mb-2">
          <h4>PGP Key</h4>
        </dt>
        <dd>
          <pgp-key :user="user" />
        </dd>

        <template v-if="hasPermission('sync_with_ciphermail')">
          <dt class="mb-2">Ciphermail</dt>
          <dd>
            <div class="mb-2">
              <form-button
                :url="syncWithCiphermail"
                :redirect="here"
                size="sm"
                variant="primary"
                :disabled="!user.pgp_key"
              >
                <font-awesome-icon icon="refresh" class="mr-1" />
                Synchronize
              </form-button>
            </div>
            <p class="text-muted small">
              Ciphermail is the gateway that ensures emails sent to this account are always encrypted using PGP. Key are
              automaticaly syncronised with Ciphermail but it might be usefull to force the synchronisation manually.
            </p>
          </dd>
        </template>

        <template v-if="hasPermission('test_email')">
          <dt class="mb-2">Test email</dt>
          <dd>
            <div class="mb-2">
              <form-button :url="sendTestEmailUrl" :redirect="here" size="sm" variant="primary">
                <font-awesome-icon icon="paper-plane" class="mr-1" />
                Send test email
              </form-button>
            </div>
            <p class="text-muted small">
              An encrypted email will be sent to
              <a :href="`mailto:${user.email}`">{{ user.email }}</a
              >. If the email is not delivered, it's very likely that the PGP key associated to that email address is
              not correct.
            </p>
          </dd>
        </template>
      </dl>
      <div class="col text-center">
        <img src="@/assets/images/shipping.svg" class="mw-100" width="250" alt="" />
      </div>
    </div>
  </div>
</template>
