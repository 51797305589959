<script>
import { findIndex, some } from 'lodash'

import UserShowMenu from '@/components/UserShowMenu'

export default {
  name: 'UserShow',
  components: {
    UserShowMenu
  },
  inject: ['api'],
  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      busy: false,
      user: null,
      userPermissions: null,
      notFound: false,
      page: null
    }
  },
  computed: {
    isNotFound() {
      return !this.busy && this.notFound
    },
    authorizedPages() {
      return this.pages.filter((page) => {
        return some(page.permissions, (permission) => {
          return this.userPermissions[permission]
        })
      })
    },
    pages() {
      return this.$config.scope('users').scope('show').get('pages')
    },
    defaultPage() {
      return this.parsedPage || this.authorizedPages[0]
    },
    parsedPage: {
      cache: false,
      get() {
        const href = this.parsedPathname
        const index = Math.max(0, findIndex(this.authorizedPages, { href }))
        return this.authorizedPages[index]
      }
    },
    parsedPathname: {
      cache: false,
      get() {
        return this.parsedHash.pathname || '/'
      }
    },
    parsedUrl: {
      cache: false,
      get() {
        return new URL(window.location)
      }
    },
    parsedHash: {
      cache: false,
      get() {
        const hash = this.parsedUrl.hash.substr(1) || '/'
        return new URL(hash, this.parsedUrl.href)
      }
    }
  },
  async created() {
    try {
      this.busy = true
      this.userPermissions = await this.api.getPermissions(this.userId)
      this.user = await this.getUser()
      this.page = this.defaultPage
      // Watch hash change to update the route
      window.addEventListener(
        'hashchange',
        () => {
          this.page = this.parsedPage
        },
        false
      )
    } catch (_) {
      this.notFound = true
    } finally {
      this.busy = false
    }
  },
  methods: {
    async getUser() {
      const response = await this.api.getUser(this.userId)
      return response?.user
    }
  }
}
</script>

<template>
  <b-overlay :show="busy" bg-color="transparent" class="user-show">
    <div v-if="busy" class="user-show__loader p-5"></div>
    <div v-else-if="isNotFound" class="user-show__not-found p-5 text-center">
      <img src="@/assets/images/404.svg" class="user-show__not-found__image mb-5" />
      <h3 class="text-center lead">User not found</h3>
    </div>
    <div v-else class="user-show__content">
      <div class="user-show__content__header page-header border-bottom-0 my-2">
        <b-img class="user-show__content__header__avatar" rounded="circle" :src="user.avatar_url_120" />
        <div>
          <h1 class="mb-0">
            {{ user.name }}
            <span v-if="!user.in_ldap_user_groups" class="text-muted small"> (deactivated) </span>
            <span v-else-if="user.locked" class="text-muted small"> (locked) </span>
          </h1>
          <span class="text-muted">@{{ user.uid }}</span>
        </div>
      </div>
      <div class="d-md-flex">
        <user-show-menu
          class="user-show__content__menu mb-3"
          :page="page"
          :user="user"
          :user-permissions="userPermissions"
        />
        <div class="user-show__content__panel flex-grow-1 px-1 ml-md-5 mb-3 overflow-auto">
          <component :is="page.component" v-bind="{ user, userPermissions }" />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<style lang="scss" scoped>
@import '../../utils/variables';

.user-show {
  min-height: 5rem;

  &__not-found {
    &__image {
      max-width: 660px;
    }
  }

  &__content {
    &__header {
      display: flex;
      align-items: center;

      &__avatar {
        background: #eee;
        height: 4rem;
        width: 4rem;
        margin-right: 1rem;
      }
    }

    &__menu {
      max-width: 240px;
      min-width: 240px;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
</style>
