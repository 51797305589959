<script>
export default {
  name: 'MagicLink',
  inject: ['api'],
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      magicLink: ''
    }
  },
  methods: {
    async generateMagicLink() {
      try {
        const { link } = await this.api.generateMagicLink(this.userId)
        this.magicLink = link
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Unable to generate the magic link',
          variant: 'danger'
        })
      }
    }
  }
}
</script>

<template>
  <div>
    <dl class="magic-link pl-0 col-10">
      <dt>Magic link</dt>

      <dd>
        <p class="magic-link__caption text-muted small">
          User will be able to start the onboarding for 24 hours using this link. See an example of onboarding
          conversation
          <a href="https://doc.cloud.icij.org/en/technology/workflows/onboarding-flow-conversation">here</a>.
        </p>
        <p>
          <b-button size="sm" variant="primary" @click="generateMagicLink">
            <fa icon="wand-magic-sparkles" class="mr-1" />
            Generate onboarding link
          </b-button>
          <template v-if="magicLink.length > 0">
            <b-form-textarea
              v-model="magicLink"
              class="magic-link__textarea text-monospace p-2 mt-4 mb-2"
              max-rows="6"
              name="magicLink"
              rows="3"
              size="sm"
              type="text"
              readonly
              spellcheck="false"
            />
            <haptic-copy type="button" class="btn btn-outline-dark btn-sm mr-2" :text="magicLink" />
          </template>
        </p>
      </dd>
    </dl>
    <p class="flow-conversation text-muted"></p>
  </div>
</template>

<style lang="scss" scoped>
@import '../utils/variables';

.magic-link {
  &__textarea {
    word-break: break-all;
    background: $input-bg;
  }

  &__caption {
    text-wrap: balance;
  }
}
</style>
