<template>
  <b-form-group id="create-user-email" class="p-3 rounded shadow-sm border">
    <b-form-group label="Primary Email Address" label-for="email" label-class="control-label--required">
      <p class="mb-2 text-muted small">
        This email is used for all encrypted communications with the account and will have to be associated with a valid
        PGP key.
      </p>
      <b-form-input id="email" :value="email" type="email" required @input="updateParentField('email', $event)" />
    </b-form-group>

    <pgp-key-input-with-criteria
      :email="email"
      :pgp-key="pgpKey"
      :autofill="false"
      @pgp-input-changed="updateParentField('pgpKey', $event)"
    >
      <template #label>
        <label>Copy or drag and drop the user's PGP key in the input below.</label>
      </template>
    </pgp-key-input-with-criteria>

    <div v-if="errors.length" class="text-danger">
      <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
    </div>

    <user-creation-navigation :validate-step="validateFields" v-on="$listeners" />
  </b-form-group>
</template>

<script>
import UserCreationNavigation from '@/components/UserForm/UserCreationNavigation'
import PgpKeyInputWithCriteria from '@/components/PgpKeyInputWithCriteria'

export default {
  name: 'EmailStep',
  components: {
    UserCreationNavigation,
    PgpKeyInputWithCriteria
  },
  props: {
    email: {
      type: String,
      required: true
    },
    pgpKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      pgpValid: true
    }
  },
  methods: {
    updateParentField(field, value) {
      if (value && typeof value === 'object' && 'pgpKey' in value) {
        this.pgpValid = value.isValid
        this.$emit('update-field', { field, value: value.pgpKey })
      } else {
        this.$emit('update-field', { field, value })
      }
    },
    validateFields() {
      this.errors = []

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(this.email)) {
        this.errors.push('Please enter a valid email address.')
      }

      return !this.errors.length
    }
  }
}
</script>
