<script>
import XemxForm from '@/components/XemxForm'

export default {
  name: 'PgpKeyInput',
  components: { XemxForm },
  model: {
    prop: 'pgpKey',
    event: 'input'
  },
  props: {
    pgpKey: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: String,
      required: false
    },
    hapticCopy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pgpInput: this.pgpKey?.slice()?.trim() || '',
      isDragging: false
    }
  },
  computed: {
    hasPgpKey() {
      return !!this.pgpKey
    },
    isPgpInputEmpty() {
      return !this.pgpInput.trim().length
    },
    isSubmitDisabled() {
      return this.pgpInput === this.pgpKey || this.isPgpInputEmpty
    },
    getIsDragging() {
      return {
        'pgp-key-input__textarea--is-dragging': this.isDragging,
        'pgp-key-input__textarea--has-key': this.hasPgpKey
      }
    }
  },
  watch: {
    pgpInput: {
      handler(value) {
        this.$emit('input', value)
      },
      immediate: true
    }
  },
  methods: {
    dragOver() {
      this.isDragging = true
    },
    dragLeave() {
      this.isDragging = false
    },
    drop(e) {
      const files = e.dataTransfer.files
      this.process(files)
    },
    process(files) {
      const file = files[0]
      if (file.type.indexOf('text/') >= 0) {
        const reader = new FileReader()
        reader.onload = (element) => {
          this.pgpInput = element.target.result
          this.isDragging = false
        }
        reader.readAsText(file)
      } else {
        this.pgpInput = this.pgpKey
        this.isDragging = false
      }
    }
  }
}
</script>

<template>
  <div class="pgp-key-input">
    <span class="pgp-key-input__label">
      <slot name="label">
        <p>Copy or drag and drop your PGP key .asc file in the input below</p>
      </slot>
    </span>
    <xemx-form method="POST" :action="redirectUrl">
      <b-card class="mb-3" no-body>
        <b-form-textarea
          v-model="pgpInput"
          class="text-monospace p-2 m-0 border-0 pgp-key__textarea"
          max-rows="10"
          name="pgp_key"
          rows="3"
          size="sm"
          type="text"
          wrap="off"
          spellcheck="false"
          :class="getIsDragging"
          placeholder="Copy your key or drag and drop your .asc file"
          debounce="500"
          @dragover.prevent="dragOver"
          @dragleave.prevent="dragLeave"
          @drop.prevent="drop($event)"
        />
        <slot name="footer" />
      </b-card>

      <slot name="actions" :pgp-input="pgpInput" :haptic-copy="hapticCopy">
        <div class="pgp-key-input__actions mb-4 d-flex">
          <b-button
            type="submit"
            variant="primary"
            size="sm"
            :disabled="isSubmitDisabled"
            class="pgp-key-input__actions--submit mr-2"
          >
            <font-awesome-icon :icon="hasPgpKey ? 'refresh' : 'add'" fixed-width />
            {{ hasPgpKey ? 'Update' : 'Add' }}
          </b-button>
          <haptic-copy
            v-if="hapticCopy"
            type="button"
            class="pgp-key-input__actions--copy btn btn-outline-dark btn-sm mr-2"
            :text="pgpInput"
            :disabled="isPgpInputEmpty"
          />
        </div>
      </slot>
    </xemx-form>
  </div>
</template>

<style scoped lang="scss">
@import 'node_modules/bootstrap/scss/_functions.scss';
@import '../utils/variables';

.pgp-key-input {
  &__textarea {
    & + .card-footer {
      color: $text-muted;
    }

    &--has-key {
      border-radius: $input-border-radius $input-border-radius 0 0;
    }

    &--is-dragging {
      background-color: mix($component-active-bg, $input-bg, 10%);
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    }
  }
}
</style>
