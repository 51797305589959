<script>
export default {
  name: 'UserCreationProgressBar',
  props: {
    userCreationStepsArray: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    steps: {
      type: Object,
      required: true
    }
  },
  computed: {
    classList() {
      return this.userCreationStepsArray.map((step) => {
        const isCompleted = this.currentStep > this.steps[step]
        const isLastActive = this.currentStep === this.userCreationStepsArray.length
        const isActive = isLastActive || this.currentStep === this.steps[step]

        return {
          'user-creation-progress-bar__list__item--completed': isCompleted,
          'user-creation-progress-bar__list__item--active': isActive,
          'user-creation-progress-bar__list__item--last-active': isLastActive
        }
      })
    }
  }
}
</script>

<template>
  <dl class="user-creation-progress-bar">
    <dd class="rounded">
      <ol class="user-creation-progress-bar__list d-flex p-0 m-0">
        <li
          v-for="(step, index) in userCreationStepsArray"
          :key="step"
          :class="classList[index]"
          class="user-creation-progress-bar__list__item"
        >
          <span class="user-creation-progress-bar__list__item__circle">
            <font-awesome-icon
              v-if="steps[step] < currentStep"
              class="user-creation-progress-bar__list__item__circle__icon"
              icon="check"
              fixed-width
            />
            <span v-else class="user-creation-progress-bar__list__item__circle__label">
              {{ index + 1 }}
            </span>
          </span>
          <span class="user-creation-progress-bar__list__item__label w-100 pt-2 text-center">{{ step }}</span>
        </li>
      </ol>
    </dd>
  </dl>
</template>

<style lang="scss" scoped>
@import '../../utils/variables';

.user-creation-progress-bar {
  --counter-size: 2em;
  --line-height: 0.5em;

  &__list {
    list-style: none;
    counter-reset: user-creation-steps;

    &__item {
      counter-increment: user-creation-steps;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      position: relative;
      padding: var(--counter-size) $spacer 0;

      &::before,
      &::after {
        content: '';
        height: var(--line-height);
        background-color: $secondary;
        position: absolute;
        width: 50%;
        top: calc(var(--counter-size) / 2);
        transform: translateY(-50%);
        z-index: 5;
      }

      &::before {
        left: 0;
      }

      &::after {
        left: 50%;
      }

      &:first-child::before {
        border-top-left-radius: var(--line-height);
        border-bottom-left-radius: var(--line-height);
      }

      &:last-child::after {
        border-top-right-radius: var(--line-height);
        border-bottom-right-radius: var(--line-height);
      }

      &__circle {
        background-color: $secondary;
        font-weight: bold;
        border-radius: 50%;
        height: var(--counter-size);
        line-height: var(--counter-size);
        width: var(--counter-size);
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        text-align: center;
        z-index: 10;
        color: white;
        box-shadow: 0 0 0 3px currentColor;
      }

      &--completed {
        &::before,
        &::after,
        .user-creation-progress-bar__list__item__circle {
          background-color: mix($primary, white);
        }
      }

      &--active {
        .user-creation-progress-bar__list__item__circle {
          background-color: $primary;
        }

        &::before {
          background-color: mix($primary, white);
        }

        &::after {
          background-color: $secondary;
        }

        .user-creation-progress-bar__list__item__label {
          font-weight: bold;
        }
      }

      &--last-active {
        &::after,
        &::before {
          background-color: $primary;
        }

        .user-creation-progress-bar__list__item__label {
          font-weight: normal;
        }

        &:last-of-type .user-creation-progress-bar__list__item__label {
          font-weight: bold;
        }
      }

      &__label {
        font-size: $font-size-base;
      }
    }
  }
}
</style>
