<template>
  <div class="user-access-blocked-applications">
    <h3>Blocked Applications</h3>
    <p>
      To prevent them from accessing some of these applications, regardless of the
      <a href="#/access/groups">groups</a> they belong to, use this form:
    </p>

    <div class="row">
      <xemx-form class="col-12 col-xl-6 mb-4" method="POST" :action="updateBlockedApplicationsUrl">
        <blocked-applications
          :selected-applications="selectedApplications"
          @update:selectedApplications="updateSelectedApplications"
        />
        <b-button type="submit" variant="primary" class="mt-2"> Update </b-button>
      </xemx-form>

      <div class="col">
        <p class="text-muted">
          To get more information on which apps and projects this user can access, you can visit the
          <a href="#/access/groups">permissions</a> page.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import BlockedApplications from '@/components/BlockedApplications'
import XemxForm from '@/components/XemxForm'

export default {
  name: 'UserAccessBlockedApplication',
  components: { BlockedApplications, XemxForm },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      applications: [],
      groups: [],
      form: cloneDeep(this.user),
      selectedApplications: this.user.blocked_applications.map((blockedApp) => blockedApp.application_id)
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    updateBlockedApplicationsUrl() {
      return `${this.root}/update_blocked_applications`
    }
  },
  methods: {
    updateSelectedApplications(newSelection) {
      this.selectedApplications = newSelection
      this.form.blocked_applications = newSelection.map((id) => ({ id }))
    }
  }
}
</script>
