<template>
  <b-form-group>
    <b-overlay :show="busy" rounded class="user-access-groups__checkbox-group-overlay">
      <div class="card p-4 mb-2 user-access-groups__card" :class="cardClass">
        <div v-for="group in groups" :key="group.id">
          <group-form-entry
            :group-id="group.id"
            :group-label="group.label_or_name"
            :group-description="group.description"
            :user-group="findUserGroup(group.id)"
            @update:userGroup="updateGroupMembership"
          />
        </div>
      </div>
    </b-overlay>
    <b-button v-if="withSubmitButton" type="submit" variant="primary"> Update </b-button>
  </b-form-group>
</template>

<script>
import GroupFormEntry from '@/components/GroupFormEntry'

export default {
  name: 'GroupForm',
  components: {
    GroupFormEntry
  },
  inject: ['api'],
  props: {
    withSubmitButton: {
      type: Boolean,
      default: false
    },
    userGroups: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: false
    },
    cardClass: {
      type: [String, Array, Object],
      default: null
    }
  },
  data() {
    return {
      busy: false,
      groups: []
    }
  },
  async created() {
    this.busy = true
    this.groups = await this.api.getGroups()
    this.busy = false
  },
  methods: {
    hasSameGroupId({ group_id: id }, groupId) {
      return id === groupId
    },
    findUserGroup(groupId) {
      return this.userGroups.find((userGroup) => this.hasSameGroupId(userGroup, groupId))
    },
    findUserGroupIndex(groupId) {
      return this.userGroups.findIndex((userGroup) => this.hasSameGroupId(userGroup, groupId))
    },
    updateGroupMembership({ groupId, partner, _destroy }) {
      const userGroupIndex = this.findUserGroupIndex(groupId)

      if (userGroupIndex === -1) {
        const newUserGroup = {
          user_id: this.userId ?? undefined,
          group_id: groupId,
          partner,
          _destroy
        }
        this.$emit('update:userGroups', [...this.userGroups, newUserGroup])
      } else {
        const updatedUserGroups = this.userGroups.map((userGroup, index) => {
          if (index === userGroupIndex) {
            const modifiedUserGroup = {
              ...userGroup,
              partner,
              _destroy
            }
            return modifiedUserGroup
          }
          return userGroup
        })
        this.$emit('update:userGroups', [...updatedUserGroups])
      }
    }
  }
}
</script>

<style scoped>
.user-access-groups__checkbox-group-overlay .card {
  min-height: 5rem;
}
.user-access-groups__card {
  max-height: 50vh;
  overflow: auto;
}
</style>
