<script>
import AppNameTooltip from '@/components/AppNameTooltip'
import PermissionIcon from '@/components/PermissionIcon'
import PermissionsCaption from '@/components/PermissionsCaption'

const BLOCK_REASONS = {
  BLOCKED: 'blocked',
  PARTNERS_ONLY: 'partners_only',
  NOT_AVAILABLE: 'not_available',
  NOT_BLOCKED: 'not_blocked'
}

const MAJOR_APPLICATIONS = ['Datashare', 'iHub']

export default {
  name: 'PermissionsTable',
  components: {
    AppNameTooltip,
    PermissionIcon,
    PermissionsCaption
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    },
    noEmptyState: {
      type: Boolean
    }
  },
  data() {
    return {
      busy: false,
      applications: [],
      groups: []
    }
  },
  computed: {
    sortedApplications() {
      return [...this.applications].sort((a, b) => {
        if (MAJOR_APPLICATIONS.includes(a.name) && !MAJOR_APPLICATIONS.includes(b.name)) {
          return -1
        }
        if (!MAJOR_APPLICATIONS.includes(a.name) && MAJOR_APPLICATIONS.includes(b.name)) {
          return 1
        }
        return a.name.localeCompare(b.name)
      })
    },
    formattedPermissions() {
      return this.groups.map((group) => {
        const formattedGroup = { group_name: group.group_name }

        group.application_list.forEach((app) => {
          formattedGroup[app.application_name] = {
            access: app.access,
            reason: app.reason,
            icon: this.getPermissionIcon(app.reason),
            tooltip: this.getIconTooltip(app.reason, app.application_name, group.group_name)
          }
        })

        return formattedGroup
      })
    },
    fields() {
      const appNames = this.sortedApplications.map((app) => ({
        key: app.name,
        label: app.name,
        env_type: app.env_type,
        thClass: ['permissions__table__header', 'border-left-0', 'border-bottom-1', 'border-top-0', 'border-right-0'],
        tdClass: ['border-top-0', 'border-left-0', 'permissions__td']
      }))
      return [
        {
          key: 'group_name',
          label: '',
          isRowHeader: true,
          stickyColumn: true,
          thStyle: {
            minWidth: '8em'
          },
          thClass: ['permissions__table__header', 'border-left-0', 'border-bottom-1', 'border-top-0', 'border-right-0'],
          tdClass: ['border-top-0', 'border-left-0', 'permissions__td']
        },
        ...appNames
      ]
    },
    blockReasons() {
      return Object.values(BLOCK_REASONS)
    }
  },
  async created() {
    this.busy = true
    this.applications = await this.api.getOauthApplications()
    this.groups = await this.api.getListGroupsUrl(this.user.id)
    this.busy = false
  },
  methods: {
    getPermissionIcon(blockReason) {
      switch (blockReason) {
        case BLOCK_REASONS.BLOCKED:
          return ['fas', 'user-lock']
        case BLOCK_REASONS.PARTNERS_ONLY:
          return ['fas', 'users-slash']
        case BLOCK_REASONS.NOT_AVAILABLE:
          return ['fas', 'times']
        case BLOCK_REASONS.NOT_BLOCKED:
          return ['fas', 'check']
        default:
          return ['fas', 'check']
      }
    },
    getIconTooltip(blockReason, appName, groupName = 'this group') {
      switch (blockReason) {
        case BLOCK_REASONS.BLOCKED:
          return `${appName || 'This application'} is blocked for ${this.user.name}`
        case BLOCK_REASONS.PARTNERS_ONLY:
          return `${appName || 'This application'} is reserved to partners for ${groupName}`
        case BLOCK_REASONS.NOT_AVAILABLE:
          return `${appName || 'This application'} is not available for ${groupName}`
        case BLOCK_REASONS.NOT_BLOCKED:
          return `${this.user.name} has access to ${groupName} on ${appName || 'this application'}`
        default:
          return `${this.user.name} has access to ${groupName} on ${appName || 'this application'}`
      }
    }
  }
}
</script>

<template>
  <div>
    <template v-if="groups.length === 0 && !noEmptyState">
      <h6 class="mb-4">This user isn't part of any group.</h6>
    </template>

    <template v-else-if="sortedApplications.length === 0 && !noEmptyState">
      <h6 class="mb-4">This user isn't part of any application.</h6>
    </template>

    <b-table
      v-else
      bordered
      responsive
      no-border-collapse
      striped
      class="permissions"
      table-class="permissions__table"
      thead-class="permissions__table__thead"
      :items="formattedPermissions"
      :fields="fields"
      :busy="busy"
    >
      <template #head()="data">
        <div v-if="data.label" class="permissions__table__header__text-container">
          <span :id="data.label + '-' + data.index" class="permissions__table__header__text-container__label">
            {{ data.label }}
          </span>
          <app-name-tooltip
            :target="data.label + '-' + data.index"
            :label="data.label"
            :env-type="data.field.env_type"
          />
        </div>
      </template>
      <template #cell()="data">
        <span v-if="data.item[data.field.key]" class="permissions__table__cell">
          <permission-icon
            v-if="data.item[data.field.key].icon"
            :permission="data.item[data.field.key]"
            :icon-id="`${data.item.group_name}-${data.field.key}`"
          />
          <span v-else>{{ data.value }}</span>
        </span>
        <span class="sr-only">
          {{ data.value }}
        </span>
      </template>
    </b-table>

    <permissions-caption
      :user="user"
      :block-reasons="blockReasons"
      :get-permission-icon="getPermissionIcon"
      :get-icon-tooltip="getIconTooltip"
    />
  </div>
</template>

<style lang="scss">
.permissions {
  &__table {
    width: fit-content !important;

    &__thead {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: white;
    }

    &__header {
      white-space: nowrap;
      height: 180px;

      &__text-container {
        transform: translate(25px, 0px) rotate(315deg);
        width: 2.5rem;
        height: 30px;
        line-height: 30px;

        &__label::after {
          position: absolute;
          left: 5px;
          content: '';
          display: block;
          height: 10px;
          border-bottom: 1px solid #dedede;
          width: 13rem;
        }

        &__label {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 12rem;
          display: inline-block;
        }
      }
    }
  }
}
</style>
