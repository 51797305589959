<template>
  <div>
    <div class="p-3 border rounded shadow-sm mb-4">
      <b-form-group label="This user has access to:" class="m-0">
        <permissions-table :user="user" no-empty-state />
      </b-form-group>
    </div>
    <div class="p-3 border rounded shadow-sm mb-4">
      <magic-link :user-id="user.id" />
    </div>
  </div>
</template>

<script>
import PermissionsTable from '@/components/PermissionsTable'
import MagicLink from '@/components/MagicLink'

export default {
  name: 'RecapStep',
  components: {
    PermissionsTable,
    MagicLink
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
